import React, { useState,useEffect } from "react";
import { useRecaptcha } from "react-hook-recaptcha";
import { useForm, Controller} from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import envConfig from "../../utils/configEnv/config";
import { TextField, Typography, Button } from '@northwell-health/nw-component-lib';
import styled from '@emotion/styled';
import { Grid, Box, Paper } from '@mui/material'

const configBaseUrl = envConfig?.REACT_APP_LOGICAPPEMAILURL;
const emailLogicAPPKey = envConfig?.REACT_APP_EMAIL_APPKEY;
const envLocal = envConfig?.REACT_APP_NODE_ENV;

const Item = styled(Paper)(() => ({
  textAlign: 'left',
  minHeight: '65px',
  lineHeight: '60px',
  maxWidth: '400px',
  marginBottom: '24px'
}));

const isValidEmail = email =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

  const postEmailRequest = async ( postData) => {
    const res = await fetch(configBaseUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": "token-value",
      },
      body: JSON.stringify(postData),
    });
  
    return res;
  };


const containerId = "test-recaptcha";
const sitekey = envConfig?.REACT_APP_GOOGLERECAPTCHASITEKEY;
const progressLeft = document.getElementsByClassName("progress-indicator-left");
const progressRight = document.getElementsByClassName("progress-indicator-right");
const dpxURL = 'https://' + envConfig?.REACT_APP_DPXURL;
const generateLiteTokenURL =  dpxURL +  '/iforms/generateLiteToken';
const sendLiteEmailURL =  dpxURL + '/iforms/sendVerificationEmail';
const iformHeaders = new Headers();
iformHeaders.append("Content-Type", "application/json");

export default function emailFormVerification({ formId, fmhFormType}) {
  const navigate = useNavigate();
  async function dpxSetAccountInfo(formData) {

    let payload = JSON.stringify({
      "email": formData.email,
      "firstName": formData.fname,
      "lastName": formData.lname,
      "formid": formId,
      "appdomain": dpxURL
    });
    try {
      const res = await fetch(generateLiteTokenURL, {
        method: "post",
        headers: iformHeaders,
        body: payload,
      });

      if (!res.ok) {
        navigate('/Error/' + 'Set Account Issue ' + res.statusText);
      }
      const resposneData = await res.json();
      await dpxSendInvite(resposneData.postGenerateLiteTokenResponse, formData);

    } catch (err) {
      navigate('/Error/' + 'Account Creation Error ' + err);
    }
  };

  async function dpxSendInvite(postGenerateLiteTokenResponse, formData) {
    let payload = JSON.stringify({
      "externalid": postGenerateLiteTokenResponse.externalid
    });
    try {

      const res = await fetch(sendLiteEmailURL, {
        method: "post",
        headers: iformHeaders,
        body: payload,
      });

      if (!res.ok) {
        navigate('/Error/' + 'Account Invite Error ');
      }
      
      const postTestData = {
        externalId:  postGenerateLiteTokenResponse.externalid,
        firstName: postGenerateLiteTokenResponse.firstName,
        lastName: postGenerateLiteTokenResponse.lastName,
        senderEmail: postGenerateLiteTokenResponse.email,
        appSecret: emailLogicAPPKey,
        formId: formId,
        fmhFormType: fmhFormType,
        environmentSet: envLocal,
      };

      const postEmailReqResults = await postEmailRequest(postTestData);

      if (postEmailReqResults) {
        navigate('/EmailNotification/' + formData.email);        
      }

    } catch (err) {
      navigate('/Error/' + 'Account Invite Error ' + err);
    }
  };

  useEffect(() => {
    progressLeft[0].style.width = "100%";
    progressRight[0].style.width = "0";
  }, [])

  const {
    control,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm();
  const [captchaResponse, setCaptchaResponse] = useState(null);
  const handleEmailValidation = email => {
    const isValid = isValidEmail(email);
    const validityChanged =
      (errors.email && isValid) || (!errors.email && !isValid);
    if (validityChanged) {}
    return isValid;
  };

  const successCallback = (response) => {
    setCaptchaResponse(response);
  };

  const handleRegistration = (data) => {
    dpxSetAccountInfo(data);
  };

  const expiredCallback = () => setCaptchaResponse(null);

  useRecaptcha({
    containerId,
    successCallback,
    expiredCallback,
    size: "normal",
    sitekey
  });

  return (
    <Box className="pageContainer">
    <Grid container
    direction="column"
    justifyContent="space-between"
    alignItems="left">
        <Grid item>
          <Typography variant="h1" style={{ marginTop: 80, marginBottom: 24 }}>Enter your details</Typography>
        </Grid>
      <form onSubmit={handleSubmit(handleRegistration)}>      
        <Grid item xs={12}>
          <Item elevation={0}>
          <Controller
          name="fname" 
          control={control} 
          render={({ field: { onChange } }) => 
          <TextField
           id="fname"
           autocomplete="given-name"
            onChange={onChange} 
            label="First name"             
            fullWidth
            required
            />} 
            rules={{ 
              required: true,
              pattern: /^[A-Z a-z]*$/i,
              }} />
            {errors?.fname?.type === "required" && <p className="red">This field is required</p>}
            {errors?.fname?.type === "pattern" && <p className="red">Please use alphanumeric characters only</p>}                    
          </Item>
          </Grid>
          <Grid item xs={12}>
          <Item elevation={0}>
            <Controller 
              name="lname" 
              control={control} 
              autocomplete="family-name"
              render={({ field: { onChange } }) => 
          <TextField id="lname" onChange={onChange}
          fullWidth 
          label="Last name" required />}
          rules={{
             required: true,
             pattern: /^[A-Z a-z]*$/i,
              }} 
              />
            {errors?.lname?.type === "required" && <p className="red">This field is required</p>}
            {errors?.lname?.type === "pattern" && <p className="red">Please use alphanumeric characters only</p>}
            </Item>
            </Grid>
            <Grid item xs={12}>
            <Item elevation={0}>
              <Controller 
              name="email" 
              control={control} 
              render={({ field: { onChange } }) => 
              <TextField id="email" 
              onChange={onChange} 
              autocomplete="off"
              label="Email address"
              fullWidth
              required />}
          rules={{
            required: true,
            pattern: /^[A-Z a-z0-9@.!#$%&'*+-/=?^_`{|}~]*$/i,
            maxLength: 122, validate: handleEmailValidation 
              }} />
            {errors?.email?.type === "required" && <p className="red">This field is required</p>}
            {errors?.email?.type === "validate" && <p className="red">Email is not in proper format</p>}
            {errors?.email?.type === "pattern" && <p className="red">Please use alphanumeric characters only</p>}
            </Item>
            </Grid>
        <Grid item xs={12}>
        <Item elevation={0}>
        <div id={containerId} className="g-recaptcha" />
        <Button disabled={!captchaResponse} 
        text="Submit" color="primary" type="submit" />
        </Item>
        </Grid>
      </form>
      </Grid>
   </Box>
      );
}
